'use client';

import * as React from 'react';
import type { ButtonProps } from '@noirproperties/ui/components/ui/button';
import { Button } from '@noirproperties/ui/components/ui/button';
import { Separator } from '@noirproperties/ui/components/ui/separator';
import LoadingDots from '@noirproperties/ui/components/icons/loading-dots';
import Link from 'next/link';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';
import { Card, CardContent } from '@noirproperties/ui/components/ui/card';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
} from '@noirproperties/ui/components/ui/carousel';
import type { RouterOutput } from '@noirproperties/functions/trpc/server';
import { toSlug } from '@noirproperties/string-utils/slug';
import type { LucideProps } from 'lucide-react';
import { Bath, Bed, SearchX } from 'lucide-react';
import { FaSwimmingPool as SwimmingPool } from 'react-icons/fa';
import type { InfiniteData } from '@tanstack/react-query';
import { cn } from '@noirproperties/ui/lib/utils';
import {
  parseAsBoolean,
  parseAsInteger,
  parseAsString,
  useQueryState,
} from 'nuqs';
import type { Options } from 'nuqs';
import { toast } from 'sonner';
import { useRouter, useSearchParams } from 'next/navigation';
import { Container } from '@noirproperties/ui/components/ui/container';
import { trpc } from '@/lib/trpc';
import type { SiteLocale } from '@/i18n-config';
import {
  useCurrencyFormatter,
  useLocalizedTextFormatter,
} from '@/lib/i18n/client';

type PropertiesListProps = {
  lang: SiteLocale;
  coast?: string;
  price?: number;
  province?: string;
  limit?: number;
  assignedToAgentID?: string;
  text: LoadMoreText;
};

type PropertiesAISearchProps = {
  lang: SiteLocale;
  query?: string;
  price?: number;
  size?: number;
  bedrooms?: number;
  bathrooms?: number;
  pool?: boolean;
  coast?: string;
  province?: string;
  text: LoadMoreText;
};

type Property = NonNullable<RouterOutput['property']['list']['data'][number]>;
type PropertyAISearch = NonNullable<
  RouterOutput['property']['search']['data'][number]
>;

// TODO: Move to a shared utility file
// TODO: Rename useSlugFromProperty since it generates a url path?
function useSlugFromProperty(lang: SiteLocale, property: Property) {
  const localizedTextFormatter = useLocalizedTextFormatter(lang);
  const province = toSlug(property.province);
  const city = toSlug(property.city);
  const title = toSlug(localizedTextFormatter(property.titles));

  return `/${lang}/properties/p/${province}/${city}/${title}-${property.propertyID}`;
}

type PropertyListQuery = {
  coast?: string;
  province?: string;
  price?: number;
  limit?: number;
  assignedToAgentID?: string;
};

function useInfiniteListProperties(query: PropertyListQuery) {
  return trpc.property.list.useInfiniteQuery(query, {
    getNextPageParam: (lastPage) => lastPage.cursor,
  });
}

type PropertyAISearchQuery = {
  query?: string;
  price?: number;
  size?: number;
  bedrooms?: number;
  bathrooms?: number;
  pool?: boolean;
  coast?: string;
  province?: string;
};

function useInfiniteAISearchProperties(query: PropertyAISearchQuery) {
  return trpc.property.search.useInfiniteQuery(query, {
    getNextPageParam: (lastPage) => lastPage.cursor,
  });
}

function useAllPagesEmpty<T>(data: InfiniteData<{ data: T[] }> | undefined) {
  return React.useMemo(
    () => data?.pages.every((page) => page.data.length === 0) || false,
    [data?.pages],
  );
}

type PropertyListProps = {
  children: React.ReactNode;
  className?: string[];
};

function PropertyAISearchList({ children, className }: PropertyListProps) {
  return (
    <ul
      className={cn(
        'grid grid-cols-1 gap-x-4 gap-y-16 lg:grid-cols-1 lg:gap-x-8 lg:gap-y-24',
        className,
      )}
    >
      {children}
    </ul>
  );
}

function PropertyList({ children, className }: PropertyListProps) {
  return (
    <ul
      className={cn(
        'grid grid-cols-1 gap-x-4 gap-y-16 lg:grid-cols-1 lg:gap-x-8 lg:gap-y-24',
        className,
      )}
    >
      {children}
    </ul>
  );
}

type PropertyListItemProps = {
  children: React.ReactNode;
  className?: string;
};

function PropertyListItem({ children, className }: PropertyListItemProps) {
  return <li className={className}>{children}</li>;
}

function EmptyPropertyList() {
  return (
    <div className="flex h-full flex-col items-center justify-center space-y-4">
      <SearchX className="h-16 w-16 text-gray-400" />
      <p className="text-lg text-gray-600">
        We could not find any properties matching your search criteria.
      </p>
    </div>
  );
}

type ErrorMessageProps = {
  message: string;
};

function ErrorMessage({ message }: ErrorMessageProps) {
  return <p>{message}</p>;
}

function Loading() {
  // TODO: Translate
  return <p>Loading...</p>;
}

type InfiniteLoadingIndicatorProps = {
  isFetching: boolean;
};

function InfiniteLoadingIndicator({
  isFetching,
}: InfiniteLoadingIndicatorProps) {
  return isFetching ? <LoadingDots /> : null;
}

type LoadMoreText = {
  loading: string;
  loadMore: string;
};

type LoadMoreButtonProps = {
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  onClick: () => void;
  text: LoadMoreText;
} & ButtonProps;

const LoadMoreButton = React.forwardRef<HTMLButtonElement, LoadMoreButtonProps>(
  (
    { hasNextPage, isFetchingNextPage, onClick, variant = 'outline', ...props },
    ref,
  ) => {
    if (!hasNextPage) {
      return null;
    }

    return (
      <Button
        disabled={isFetchingNextPage}
        onClick={onClick}
        ref={ref}
        type="button"
        variant={variant}
        {...props}
      >
        {}
        {isFetchingNextPage ? props.text.loading : props.text.loadMore}
      </Button>
    );
  },
);

LoadMoreButton.displayName = 'LoadMoreButton';

export function PropertiesAISearchListFlat({
  lang,
  query,
  price,
  size,
  bedrooms,
  bathrooms,
  pool,
  coast,
  province,
  text,
}: PropertiesAISearchProps) {
  const {
    data,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteAISearchProperties({
    query,
    price,
    size,
    bedrooms,
    bathrooms,
    pool,
    coast,
    province,
  });

  const isEmpty = useAllPagesEmpty(data);

  // Flatten and sort all items in the useMemo hook
  const allItems = React.useMemo(() => {
    return (
      data?.pages
        .flatMap((page) => page.data)
        .sort((a, b) => b.score - a.score) || []
    );
  }, [data]);

  if (data) {
    return (
      <>
        {!isEmpty ? (
          <>
            <PropertyAISearchList>
              {allItems.map((property) => (
                <PropertyListItem
                  className="relative"
                  key={property.propertyID}
                >
                  {/* TODO: Handle if there are no images */}
                  <PropertyListingAISearch lang={lang} property={property} />
                </PropertyListItem>
              ))}
            </PropertyAISearchList>
            <div className="flex flex-col items-center pt-10">
              <InfiniteLoadingIndicator isFetching={isFetching} />
              <LoadMoreButton
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onClick={() => void fetchNextPage()}
                text={text}
              />
            </div>
          </>
        ) : (
          <EmptyPropertyList />
        )}
      </>
    );
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  return <Loading />;
}

export function PropertiesAISearchList({
  // TODO: Use Language provider instead of passing everywhere?
  lang,
  query,
  text,
}: PropertiesAISearchProps) {
  const {
    data,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteAISearchProperties({ query });

  const isEmpty = useAllPagesEmpty(data);

  if (data) {
    return (
      <>
        {!isEmpty ? (
          <>
            <PropertyList>
              {data.pages.map((group, i) => (
                // eslint-disable-next-line react/no-array-index-key -- We are not mutating the array
                <React.Fragment key={i}>
                  {group.data.map((property) => (
                    <PropertyListItem
                      className="relative"
                      key={property.propertyID}
                    >
                      {/* TODO: Handle if there are no images */}
                      <PropertyListingAISearch
                        lang={lang}
                        property={property}
                      />
                    </PropertyListItem>
                  ))}
                </React.Fragment>
              ))}
            </PropertyList>
            <div className="flex flex-col items-center pt-10">
              <InfiniteLoadingIndicator isFetching={isFetching} />
              <LoadMoreButton
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onClick={() => void fetchNextPage()}
                text={text}
              />
            </div>
          </>
        ) : (
          <EmptyPropertyList />
        )}
      </>
    );
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  return <Loading />;
}

export function PropertiesList({
  // TODO: Use Language provider instead of passing everywhere?
  lang,
  coast,
  province,
  price,
  limit,
  assignedToAgentID,
  text,
}: PropertiesListProps) {
  const {
    data,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteListProperties({
    coast,
    province,
    price,
    limit,
    assignedToAgentID,
  });

  const isEmpty = useAllPagesEmpty(data);

  if (data) {
    return (
      <>
        {!isEmpty ? (
          <>
            <PropertyList>
              {data.pages.map((group, i) => (
                // eslint-disable-next-line react/no-array-index-key -- We are not mutating the array
                <React.Fragment key={i}>
                  {group.data.map((property) => (
                    <PropertyListItem
                      className="relative"
                      key={property.propertyID}
                    >
                      {/* TODO: Handle if there are no images */}
                      <React.Suspense>
                        <PropertyListing lang={lang} property={property} />
                      </React.Suspense>
                    </PropertyListItem>
                  ))}
                </React.Fragment>
              ))}
            </PropertyList>
            <div className="flex flex-col items-center pt-10">
              <InfiniteLoadingIndicator isFetching={isFetching} />
              <LoadMoreButton
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onClick={() => void fetchNextPage()}
                text={text}
              />
            </div>
          </>
        ) : (
          <EmptyPropertyList />
        )}
      </>
    );
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  return <Loading />;
}

type PropertiesInfiniteListProps = {
  lang: SiteLocale;
  coast?: string;
  price?: number;
  province?: string;
  limit?: number;
  text: LoadMoreText;
  assignedToAgentID?: string;
};

export function PropertiesInfiniteList({
  // TODO: Use Language provider instead of passing everywhere?
  lang,
  coast,
  province,
  price,
  limit,
  text,
  assignedToAgentID,
}: PropertiesInfiniteListProps) {
  const {
    data,
    error,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isFetching,
  } = useInfiniteListProperties({
    coast,
    province,
    price,
    limit,
    assignedToAgentID,
  });

  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView && (hasNextPage || !isFetching)) {
      void fetchNextPage();
    }
  }, [inView, hasNextPage, isFetching, fetchNextPage]);

  const isEmpty = useAllPagesEmpty(data);

  if (data) {
    return (
      <>
        {!isEmpty ? (
          <>
            <PropertyList>
              {data.pages.map((group, i) => (
                // eslint-disable-next-line react/no-array-index-key -- We are not mutating the array
                <React.Fragment key={i}>
                  {group.data.map((property) => (
                    <PropertyListItem
                      className="relative"
                      key={property.propertyID}
                    >
                      <React.Suspense>
                        <PropertyListing lang={lang} property={property} />
                      </React.Suspense>
                    </PropertyListItem>
                  ))}
                </React.Fragment>
              ))}
            </PropertyList>
            <div className="flex flex-col items-center pt-10">
              <InfiniteLoadingIndicator isFetching={isFetching} />
              <LoadMoreButton
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                onClick={() => void fetchNextPage()}
                ref={ref}
                text={text}
              />
            </div>
          </>
        ) : (
          <EmptyPropertyList />
        )}
      </>
    );
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  return <Loading />;
}

type PropertyListingProps = {
  lang: SiteLocale;
  property: Property;
};

// Define types for filter parameters
type FilterType = 'price' | 'size' | 'bedrooms' | 'bathrooms';

function PropertyListing({ lang, property }: PropertyListingProps) {
  const url = useSlugFromProperty(lang, property);
  const currencyFormatter = useCurrencyFormatter(lang);
  const localizedTextFormatter = useLocalizedTextFormatter(lang);

  const router = useRouter();
  const searchParams = useSearchParams();

  const handleFilterClick = React.useCallback(
    (filterType: FilterType, value: number) => {
      const params = new URLSearchParams(searchParams.toString());

      params.set(filterType, value.toString());
      router.push(`/properties/search?${params.toString()}`);
    },
    [router, searchParams],
  );

  return (
    <div className="grid grid-cols-1 gap-x-4 lg:grid-cols-6 lg:gap-x-4">
      <div className="col-span-full md:col-span-4">
        <Container className="mx-0 px-0 py-4 md:max-w-4xl lg:mx-auto lg:max-w-screen-lg">
          <Carousel
            opts={{
              align: 'start',
              loop: true,
            }}
          >
            <CarouselContent>
              {property.images.map((image) => (
                <CarouselItem
                  className="md:basis-1/1 lg:basis-1/1"
                  key={image.id}
                >
                  <div className="">
                    <Card>
                      <CardContent className="aspect-h-9 aspect-w-16 flex h-screen max-h-[35vh] w-full items-center justify-center overflow-hidden rounded-none bg-gray-100 p-2 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 sm:max-h-[40vh] md:max-h-[45vh]">
                        <Link
                          className="focus:outline-none"
                          href={url}
                          type="button"
                        >
                          <span className="sr-only">
                            View details of property
                          </span>

                          <Image
                            alt=""
                            className="cursor-grab object-cover"
                            fill
                            // sizes="(max-width: 640px) 100vw, 50vw"
                            src={image.url}
                          />
                        </Link>
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            {/* <div className="flex items-center justify-between py-2"> */}
            {/* <div className="flex pe-10"> */}

            {/* TODO: Maybe not hide prev/next on mobile? */}
            <CarouselPrevious
              className="left-2 hidden sm:flex"
              variant="secondary"
            />
            <CarouselNext
              className="right-2 hidden sm:flex"
              variant="secondary"
            />

            {/* </div> */}
            {/* <CarouselDots className="py-2" /> */}
            {/* </div> */}
          </Carousel>
        </Container>
      </div>

      <div className="relative isolate col-span-full md:col-span-2">
        <Container className="mx-0 lg:mx-auto lg:px-0">
          {/* Main Link with Pseudo-element */}
          <Link
            className="absolute inset-0 z-10 focus:outline-none"
            href={url}
            type="button"
          >
            <span className="sr-only">View details of property</span>
          </Link>

          {/* Content that appears above the link */}
          <div className="relative z-20 mt-2 space-y-1">
            <p className="truncate text-xs font-semibold uppercase text-neutral-600 sm:text-sm lg:text-sm">
              {property.construction.type}
            </p>
            <p className="lg:text-md truncate text-sm font-semibold text-neutral-950">
              {localizedTextFormatter(property.titles)}
            </p>
            <p className="truncate text-xs font-medium text-neutral-800 lg:text-sm">
              {property.province}, {property.city}, {property.area}
            </p>
            <div className="pt-2">
              <p className="text-md font-medium text-neutral-600 lg:text-lg">
                {currencyFormatter(property.price)}
              </p>
            </div>
          </div>

          <div className="mt-4 flex items-center space-x-4 text-neutral-600">
            {property.construction.area ? (
              <ConstructionArea area={property.construction.area} />
            ) : null}

            {property.bedrooms ? (
              <Bedrooms bedrooms={property.bedrooms} />
            ) : null}

            {property.bathrooms ? (
              <Bathrooms bathrooms={property.bathrooms} />
            ) : null}

            <Pool pool={property.pool} />
          </div>

          <div className="mt-4">
            <Separator className="my-4 bg-neutral-100" />
            <div className="flex flex-col">
              <Button
                className="font-display relative z-30 inline-flex items-center justify-start gap-x-1 pl-0 text-xs leading-6 text-neutral-600 sm:w-auto"
                // disabled={priceQueryParam === property.price}
                // onClick={() => {
                //   if (priceQueryParam === property.price) {
                //     toast.warning('This filter is already active');
                //     return;
                //   }
                //   void setPriceQueryParam(property.price);
                // }}
                onClick={() => {
                  handleFilterClick('price', property.price);
                }}
                variant="link"
              >
                Filter by similar price <span aria-hidden="true">&rarr;</span>
              </Button>
              <Button
                className="font-display relative z-30 inline-flex items-center justify-start gap-x-1 pl-0 text-xs leading-6 text-neutral-600 sm:w-auto"
                // disabled={areaQueryParam === property.construction.area}
                // onClick={() => {
                //   if (sizeQueryParam === property.construction.area) {
                //     toast.warning('This filter is already active');
                //     return;
                //   }

                //   void setSizeQueryParam(property.construction.area);
                // }}
                onClick={() => {
                  handleFilterClick('size', property.construction.area ?? 1);
                }}
                variant="link"
              >
                Filter by similar size <span aria-hidden="true">&rarr;</span>
              </Button>
              <Button
                className="font-display relative z-30 inline-flex items-center justify-start gap-x-1 pl-0 text-xs leading-6 text-neutral-600 sm:w-auto"
                // disabled={bedroomsQueryParam === property.bedrooms}
                // onClick={() => {
                //   if (bedroomsQueryParam === property.bedrooms) {
                //     toast.warning('This filter is already active');
                //     return;
                //   }
                //   void setBedroomsQueryParam(property.bedrooms);
                // }}
                onClick={() => {
                  handleFilterClick('bedrooms', property.bedrooms ?? 1);
                }}
                variant="link"
              >
                Filter by similar bedrooms{' '}
                <span aria-hidden="true">&rarr;</span>
              </Button>
              <Button
                className="font-display relative z-30 inline-flex items-center justify-start gap-x-1 pl-0 text-xs leading-6 text-neutral-600 sm:w-auto"
                // disabled={bathroomsQueryParam === property.bathrooms}
                // onClick={() => {
                //   if (bathroomsQueryParam === property.bathrooms) {
                //     toast.warning('This filter is already active');
                //     return;
                //   }

                //   void setBathroomsQueryParam(property.bathrooms);
                // }}
                onClick={() => {
                  handleFilterClick('bathrooms', property.bathrooms ?? 1);
                }}
                variant="link"
              >
                Filter by similar bathrooms{' '}
                <span aria-hidden="true">&rarr;</span>
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

type PropertyListingAISearchProps = {
  lang: SiteLocale;
  property: PropertyAISearch;
};

export function usePriceQueryParam(options?: Partial<Options>) {
  return useQueryState(
    'price',
    parseAsInteger.withOptions({
      shallow: false,
      scroll: false,
      ...options,
    }),
  );
}

export function useSizeQueryParam(options?: Partial<Options>) {
  return useQueryState(
    'size',
    parseAsInteger.withOptions({
      shallow: false,
      scroll: false,
      ...options,
    }),
  );
}

export function useBedroomsQueryParam(options?: Partial<Options>) {
  return useQueryState(
    'bedrooms',
    parseAsInteger.withOptions({
      shallow: false,
      scroll: false,
      ...options,
    }),
  );
}

export function useBathroomsQueryParam(options?: Partial<Options>) {
  return useQueryState(
    'bathrooms',
    parseAsInteger.withOptions({
      shallow: false,
      scroll: false,
      ...options,
    }),
  );
}

export function useQueryQueryParam(options?: Partial<Options>) {
  return useQueryState(
    'query',
    parseAsString.withDefault('').withOptions({
      shallow: false,
      scroll: false,
      ...options,
    }),
  );
}

export function useCoastQueryParam(options?: Partial<Options>) {
  return useQueryState('coast', {
    shallow: false,
    scroll: false,
    ...options,
  });
}

export function useProvinceQueryParam(options?: Partial<Options>) {
  return useQueryState('province', {
    shallow: false,
    scroll: false,
    ...options,
  });
}

export function usePoolQueryParam(options?: Partial<Options>) {
  return useQueryState(
    'pool',
    parseAsBoolean.withOptions({
      shallow: false,
      scroll: false,
      ...options,
    }),
  );
}

function PropertyListingAISearch({
  lang,
  property,
}: PropertyListingAISearchProps) {
  const url = useSlugFromProperty(lang, property);
  const currencyFormatter = useCurrencyFormatter(lang);
  const localizedTextFormatter = useLocalizedTextFormatter(lang);

  const [priceQueryParam, setPriceQueryParam] = usePriceQueryParam({
    scroll: true,
  });
  const [sizeQueryParam, setSizeQueryParam] = useSizeQueryParam({
    scroll: true,
  });
  const [bedroomsQueryParam, setBedroomsQueryParam] = useBedroomsQueryParam({
    scroll: true,
  });
  const [bathroomsQueryParam, setBathroomsQueryParam] = useBathroomsQueryParam({
    scroll: true,
  });

  return (
    <div className="grid grid-cols-1 gap-x-4 lg:grid-cols-5 lg:gap-x-16">
      <div className="col-span-3">
        <Carousel
          className="aspect-h-7 w-full"
          opts={{
            align: 'start',
            loop: true,
          }}
        >
          <CarouselContent>
            {property.images.map((image) => (
              <CarouselItem
                className="md:basis-1/1 lg:basis-1/1"
                key={image.id}
              >
                <div className="">
                  <Card>
                    <CardContent className="aspect-h-9 aspect-w-16 flex h-96 w-full items-center justify-center overflow-hidden rounded-none bg-gray-100 p-2 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                      <Link
                        className="focus:outline-none"
                        href={url}
                        type="button"
                      >
                        <span className="sr-only">
                          View details of property
                        </span>

                        <Image
                          alt=""
                          className="cursor-grab object-cover"
                          fill
                          sizes="(max-width: 640px) 100vw, 50vw"
                          src={image.url}
                        />
                      </Link>
                    </CardContent>
                  </Card>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          {/* <div className="flex items-center justify-between py-2"> */}
          {/* <div className="flex pe-10"> */}
          {/* TODO: Maybe not hide prev/next on mobile? */}

          <CarouselPrevious
            className="left-2 hidden sm:flex"
            variant="secondary"
          />
          <CarouselNext
            className="right-2 hidden sm:flex"
            variant="secondary"
          />
          {/* </div> */}
          {/* TODO: Replace with a simpler counter, i.e. "1/20"
          or steal buttons from previous slider implementation from the library */}
          {/* <CarouselDots className="py-2" /> */}
          {/* </div> */}
        </Carousel>
      </div>

      <div className="relative isolate col-span-2">
        {/* Main Link with Pseudo-element */}
        <Link
          className="absolute inset-0 z-10 focus:outline-none"
          href={url}
          type="button"
        >
          <span className="sr-only">View details of property</span>
        </Link>

        {/* Content that appears above the link */}
        <div className="relative z-20 mt-2 space-y-1">
          <p className="truncate text-xs font-semibold uppercase text-neutral-600 sm:text-sm lg:text-sm">
            {property.construction.type}
          </p>
          <p className="lg:text-md truncate text-sm font-semibold text-neutral-950">
            {localizedTextFormatter(property.titles)}
          </p>
          <p className="truncate text-xs font-medium text-neutral-800 lg:text-sm">
            {property.province}, {property.city}, {property.area}
          </p>
          <div className="pt-2">
            <p className="text-md font-medium text-neutral-600 lg:text-lg">
              {currencyFormatter(property.price)}
            </p>
          </div>
        </div>

        <div className="mt-4 flex items-center space-x-4 text-neutral-600">
          {property.construction.area ? (
            <ConstructionArea area={property.construction.area} />
          ) : null}

          {property.bedrooms ? <Bedrooms bedrooms={property.bedrooms} /> : null}

          {property.bathrooms ? (
            <Bathrooms bathrooms={property.bathrooms} />
          ) : null}

          <Pool pool={property.pool} />
        </div>

        <div className="mt-4">
          <p className="truncate text-xs font-medium text-neutral-800 lg:text-sm">
            Score: {property.score}
          </p>
          <Separator className="my-4 bg-neutral-100" />
          <div className="flex flex-col">
            <Button
              className="font-display relative z-30 inline-flex items-center justify-start gap-x-1 pl-0 text-xs leading-6 text-neutral-600 sm:w-auto"
              // disabled={priceQueryParam === property.price}
              onClick={() => {
                if (priceQueryParam === property.price) {
                  toast.warning('This filter is already active');
                  return;
                }
                void setPriceQueryParam(property.price);
              }}
              variant="link"
            >
              Filter by similar price <span aria-hidden="true">&rarr;</span>
            </Button>
            <Button
              className="font-display relative z-30 inline-flex items-center justify-start gap-x-1 pl-0 text-xs leading-6 text-neutral-600 sm:w-auto"
              // disabled={areaQueryParam === property.construction.area}
              onClick={() => {
                if (sizeQueryParam === property.construction.area) {
                  toast.warning('This filter is already active');
                  return;
                }

                void setSizeQueryParam(property.construction.area);
              }}
              variant="link"
            >
              Filter by similar size <span aria-hidden="true">&rarr;</span>
            </Button>
            <Button
              className="font-display relative z-30 inline-flex items-center justify-start gap-x-1 pl-0 text-xs leading-6 text-neutral-600 sm:w-auto"
              // disabled={bedroomsQueryParam === property.bedrooms}
              onClick={() => {
                if (bedroomsQueryParam === property.bedrooms) {
                  toast.warning('This filter is already active');
                  return;
                }
                void setBedroomsQueryParam(property.bedrooms);
              }}
              variant="link"
            >
              Filter by similar bedrooms <span aria-hidden="true">&rarr;</span>
            </Button>
            <Button
              className="font-display relative z-30 inline-flex items-center justify-start gap-x-1 pl-0 text-xs leading-6 text-neutral-600 sm:w-auto"
              // disabled={bathroomsQueryParam === property.bathrooms}
              onClick={() => {
                if (bathroomsQueryParam === property.bathrooms) {
                  toast.warning('This filter is already active');
                  return;
                }

                void setBathroomsQueryParam(property.bathrooms);
              }}
              variant="link"
            >
              Filter by similar bathrooms <span aria-hidden="true">&rarr;</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

type ConstructionAreaProps = {
  area: number;
  icon?: React.ForwardRefExoticComponent<
    Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
  >;
};

function ConstructionArea(props: ConstructionAreaProps) {
  return (
    <div className="flex items-center space-x-1">
      {props.icon ? <props.icon className="size-4" strokeWidth={2.5} /> : null}
      <p className="lg:text-md mb-0 mt-0 text-sm font-semibold">
        {props.area} m<sup>2</sup>
      </p>
    </div>
  );
}

type PropertyNumericFeatureProps = {
  count: number;
  labels: {
    singular: string;
    plural: string;
  };
  Icon: React.ForwardRefExoticComponent<
    Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
  >;
};

function PropertyNumericFeature({
  count,
  labels,
  Icon,
}: PropertyNumericFeatureProps) {
  const label = count === 1 ? labels.singular : labels.plural;
  return (
    <div className="flex items-center space-x-1">
      <Icon className="size-4" strokeWidth={2.5} />
      <p className="mb-0 mt-0 text-xs font-semibold lg:text-sm">
        {count} {label}
      </p>
    </div>
  );
}

type BedroomsProps = {
  bedrooms: number;
};

function Bedrooms({ bedrooms }: BedroomsProps) {
  return (
    <PropertyNumericFeature
      Icon={Bed}
      count={bedrooms}
      labels={{ singular: 'bed', plural: 'beds' }}
    />
  );
}

type BathroomProps = {
  bathrooms: number;
};

function Bathrooms({ bathrooms }: BathroomProps) {
  return (
    <PropertyNumericFeature
      Icon={Bath}
      count={bathrooms}
      labels={{ singular: 'bath', plural: 'baths' }}
    />
  );
}

type PoolProps = {
  pool: boolean;
};

function Pool({ pool }: PoolProps) {
  if (!pool) return null;

  return (
    <div className="flex items-center space-x-1">
      <SwimmingPool className="size-4" strokeWidth={2.5} />
      <p className="mb-0 mt-0 text-xs font-semibold">Pool</p>
    </div>
  );
}
